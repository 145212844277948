body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 15vw;
  user-select: none;
  text-shadow: black;
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  color: grey;
  outline: none;

  &:hover {
    color: lime;
  }
}

.western-font {
  font-family: rosewood-std, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.shadow-font {
  filter: invert(1);
  mix-blend-mode: difference;
}

.root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.flex {
  display: flex;

  &.flex-row {
    flex-direction: row;
  }

  &.flex-column {
    flex-direction: column;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-fill {
  flex: 1;
}