.socials-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 1rem;
  bottom: 1rem;

  a {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;

    &.brand-icon-link {
      background-color: white;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0.4em 0em black;

      &:not(:nth-child(1)) {
        margin-top: 0.5rem;
      }

      &:hover {
        background-color: #E0E0E0;
      }

      &.email {
        color: black;
      }

      &.facebook {
        color: #4267b2;
      }

      &.instagram {
        color: #833ab4;
      }

      &.youtube {
        color: #ff0000;
      }

      &.soundcloud {
        color: #fe5000;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .socials-container {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.84);

    a {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
      margin-top: 0;
      margin-bottom: 0;

      &.brand-icon-link {
        background-color: unset;
        border-radius: unset;
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;

        &:not(:nth-child(1)) {
          margin-top: 0;
        }
  
        &.email {
          color: white;
        }
      }
    }
  }
}
