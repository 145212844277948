.content-area {
  background-color: rgba(0, 0, 0, 0.84);
  max-width: 1200px;
  margin: auto;
  padding: 0 2em 2em 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;

  .link-box {
    display: flex;

    a {
      color: white;
      text-decoration: none;
      outline: none;
      margin: 0 2rem;

      &:hover {
        color: lime;
      }
    }
  }
}

.background-image-container {
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-image: url("../public/assets/images/Samwhiskey_2020_09_26_Brackenheim_Epizentrum_FSJlerin_Julia_X_kleinskaliert.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.simple-bar {
  height: 100vh;
  max-height: 100vh;
}

@media only screen and (max-width: 600px) {
  .simple-bar {
    height: calc(100vh - 4em);
    max-height: calc(100vh - 4em);
  }
}
