.gigs-overview {
  align-items: center;
}

.gigs-header {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  &:first {
    margin-top: 0;
  }
}

.grid {
  width: 100%;
  font-size: 1.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr;
  grid-template-areas: "date time locality city";

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas: "content";

    > .gig:not(:last-child) > div:nth-child(4n) {
      margin-bottom: 1rem;
    }
  }
}

.pool {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .gig {
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-of-type) {
      &::after {
        content: "\2022";
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }

    > div {
      padding-right: 0.5rem;
      word-wrap: break-word;

      &:last-of-type,
      &:empty {
        padding-right: 0;
      }
    }
  }
}
