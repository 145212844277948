.highlight {
  font-weight: bold;
  font-size: 1.3rem;
}

.gig {
  display: contents;

  div:empty {
    content: '&nbsp;';
  }

  &:hover > div {
    background-color: rgba($color: #4e4e4e, $alpha: 0.5);
  }
}

.date {
  text-align: start;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}

.time {
  text-align: center;
}

.locality {
  text-align: center;
}

.city {
  text-align: end;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}
