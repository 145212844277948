@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

.album-header {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.album-container {
  width: 100%;
  @include align-items(center);
  @include justify-content(center);

  .album {
    width: 80%;

    .album-link {
      width: 100%;
      aspect-ratio: 1 / 1;

      .album-image {
        width: 100%;
        height: 100%;

        .image-container {
          width: 100%;
          aspect-ratio: 1 / 1;

          img {
            width: 100%;
            object-fit: cover; /* making sure the image isn't distorted */
            aspect-ratio: 1 / 1;
          }
        }
      }
    }
  }
}
