a {
  color: white;
  outline: none;

  &:hover {
    color: lime;
  }
}

.time-disclaimer {
    text-align: center;
}