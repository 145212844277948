.image-container {
    overflow: hidden;
    position: relative;
    margin: 20px auto;
  }
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: contain;
  
    &.thumb {
      opacity: 1;
      filter: blur(10px);
      transition: opacity 1s ease-out;
      position: absolute;
      &.isLoaded {
        opacity: 0;
      }
    }
  
    &.isLoaded {
      transition: opacity 1s ease-out;
      opacity: 1;
    }
  }
  