header {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: flex-end;
    color: black;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    header {
      height: calc(100vh - 4em);
      max-height: calc(100vh - 4em);
    }
  }
  